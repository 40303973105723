<template>
    <el-container style="height: 100%; border: 1px solid #eee">
        <div class="avue-sidebar leftNav" style="">
            <div style="position:absolute;width:100%;height:60px;line-height:60px;font-size:32px;font-weight:700;padding:0 20px;z-index:1;left:0;top:10px;box-sizing:border-box;">PVH</div>
            <el-scrollbar style="height:100%">
                <el-menu class=""
                         v-for="(item,index) in setNav.leftNav"
                         :key="index"
                         unique-opened
                         router
                         :default-active="$route.path"
                         @select="handleSelect"
                         text-color="#909399"
                         style="width:285px;"
                         :collapse="keyCollapse"
                         mode="vertical"
                         :show-timeout="200">
                    <component class="menu-item" :is="(item.children&&item.children.length>0)?'el-submenu':'el-menu-item'" :index="item.url">
                        <template slot="title">
                            <i :class="[item.icon]"></i>
                            <span>{{item.title}}</span>
                        </template>
                        <template v-if="item.children&&item.children.length>0">
                            <el-menu-item class="menu-item" v-for="(v,i) in item.children" :key="v.url+i" :index="v.url">
                                <i :class="[v.icon]"></i>
                                <span slot="title">{{v.title}}</span>
                            </el-menu-item>
                        </template>
                    </component>
                </el-menu>
            </el-scrollbar>
        </div>
        <div class="rightCon" style=" width: calc(100% - 220px);">
            <div class="userInfo">
                <div>
                    <template>
                        <el-button @click="setCollapse" type="primary" icon="iconfont icon-pvhn22" style="font-size: 18px;"></el-button>
                    </template>
                </div>
                <div style="display:flex;align-items: center;">
                    <template>
                        <el-select v-model="value" @change="getValue" style="width:100px;">
                            <el-option v-for="item in language" :key="item.value" :label="item.label" :value="item.value">
                            </el-option>
                        </el-select>
                    </template>
                    <template>
                        <div class="demo-type" style="display: flex; align-items: center; margin-left: 20px;">
                            <span style="padding:0 10px;">pvh</span>
                            <el-avatar :size="40" src="https://empty" @error="errorHandler">
                                <img src="https://cube.elemecdn.com/e/fd/0fc7d20532fdaf769a25683617711png.png" />
                            </el-avatar>
                        </div>
                    </template>
                </div>
            </div>
            <el-container style="height: 100%; overflow-x: hidden; overflow-y: scroll;width:100%;padding-bottom:70px;">
                <el-main class="pvhContent" style="padding:20px 40px !important;">
                    <template>
                        <router-view></router-view>
                    </template>
                </el-main>
            </el-container>
        </div>
    </el-container>
</template>
<style>
    .userInfo {
        text-align: right;
        padding: 20px 40px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        box-shadow: 0 0 5px 5px rgba(0,0,0,.05);
    }
</style>
<script>
    document.documentElement.style.fontSize = "inherit";
    export default {
        name: "pvh",
        data() {
            let defVlue = "";
            if (this.$i18n.locale == "ch") {
                defVlue = "Chinese";
            } else {
                defVlue = "English";
            }
            const item = {
                date: '2016-05-02',
                name: '王小虎',
                address: '上海市普陀区金沙江路 1518 弄'
            };
            return {
                tableData: Array(20).fill(item),
                language: [{
                    value: 'English',
                    label: 'English'
                }, {
                    value: 'Chinese',
                    label: '中文'
                }],
                quaflat: "0",
                value: defVlue,
                drawer: false,

            }
        },
        mounted() {
            if (window.innerWidth >= 992) {
                document.querySelector(".leftNav").style.cssText = "transition: all .5s;position: relative;left: 0px";
                document.querySelector(".rightCon").style.cssText = "transition: all .5s;width:calc(100% - 220px);";
            } else {
                document.querySelector(".leftNav").style.cssText = "transition: all .5s;position: fixed;left: -250px";
                document.querySelector(".rightCon").style.cssText = "transition: all .5s;width:100%;";
            }
            window.onresize = function () {
                document.documentElement.style.fontSize = "inherit";
                if (window.innerWidth >= 992) {
                    document.querySelector(".leftNav").style.cssText = "transition: all .5s;position: relative;left: 0px";
                    document.querySelector(".rightCon").style.cssText = "transition: all .5s;width:calc(100% - 220px);";
                } else {
                    document.querySelector(".leftNav").style.cssText = "transition: all .5s;position: fixed;left: -250px";
                    document.querySelector(".rightCon").style.cssText = "transition: all .5s;width:100%;";
                }
            }
        },
        computed: {
            setNav() {
                return {
                    leftNav: [
                        {
                            icon: 'iconfont icon-dashboard',
                            title: this.$t('Dashboard'),
                            url: '/'
                        },
                        {
                            icon: 'iconfont icon-hudongguanli',
                            title: this.$t('Product tracing'),
                            url: '/ProductManage'
                        },
                        {
                            icon: 'iconfont icon-dianpu',
                            title: this.$t('Supplier'),
                            url: '/pvh_setting/supplierList'
                        },
                        {
                            icon: 'iconfont icon-moxing',
                            title: this.$t('Materials'),
                            url: '/AccountManage',
                            children: [{
                                icon: 'el-icon-tickets',
                                title: 'test',
                                url: '/'
                            }]
                        },
                        {
                            icon: 'iconfont icon-jiaoseleixing',
                            title: this.$t('User/Role'),
                            url: '/'
                        }
                    ]
                }
            }
        },
        methods: {
            setCollapse() {
                let ln = document.querySelector(".leftNav").offsetLeft;
                if (ln >= 1) {
                    document.querySelector(".leftNav").style.cssText = "transition: all .5s;position: fixed;left: -250px";
                    document.querySelector(".rightCon").style.cssText = "transition: all .5s;width:100%;";
                } else {
                    document.querySelector(".leftNav").style.cssText = "transition: all .5s;position: relative;left: 0px";
                    document.querySelector(".rightCon").style.cssText = "transition: all .5s;width:calc(100% - 220px);";
                }
            },
            getValue: function (value) {
                if (value == 'Chinese') {
                    localStorage.setItem('locale', 'ch')
                    this.$i18n.locale = localStorage.getItem('locale')
                } else if (value == 'English') {
                    localStorage.setItem('locale', 'en')
                    this.$i18n.locale = localStorage.getItem('locale')
                }
            },
            handleSelect(key, keyPath) {
                this.drawer = false;
            },
            handleOpen(key, keyPath) {
                console.log(key, keyPath);
            },
            handleClose(key, keyPath) {
                console.log(key, keyPath);
            },
            errorHandler() {
                return true
            }
        }
    }
</script>