var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "avue-sidebar" },
    [
      _c("logo"),
      _c(
        "el-scrollbar",
        { staticStyle: { height: "100%" } },
        [
          _vm.validatenull(_vm.menu)
            ? _c("div", { staticClass: "avue-sidebar--tip" }, [
                _vm._v("没有发现菜单")
              ])
            : _vm._e(),
          _c(
            "el-menu",
            {
              attrs: {
                "unique-opened": "",
                "default-active": _vm.nowTagValue,
                mode: "vertical",
                "show-timeout": 200,
                "background-color": "#20222a",
                "text-color": "rgba(255,255,255,0.7)",
                collapse: _vm.keyCollapse
              }
            },
            [
              _c("sidebar-item", {
                attrs: {
                  menu: _vm.menu,
                  first: "",
                  screen: _vm.screen,
                  props: _vm.website.menu.props,
                  collapse: _vm.keyCollapse
                }
              })
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }