var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "avue-contail",
      class: { "avue--collapse": _vm.isCollapse }
    },
    [
      _c("div", { staticClass: "avue-header" }, [_c("top")], 1),
      _c("div", { staticClass: "avue-layout" }, [
        _c("div", { staticClass: "avue-left" }, [_c("sidebar")], 1),
        _c(
          "div",
          { staticClass: "avue-main" },
          [
            _c("tags"),
            _c(
              "el-scrollbar",
              {
                staticStyle: { height: "100%" },
                attrs: { id: "leftHeight", viewStyle: _vm.viewStyle }
              },
              [
                _c(
                  "keep-alive",
                  [
                    _vm.$route.meta.$keepAlive
                      ? _c("router-view", { staticClass: "avue-view" })
                      : _vm._e()
                  ],
                  1
                ),
                !_vm.$route.meta.$keepAlive
                  ? _c("router-view", { staticClass: "avue-view" })
                  : _vm._e()
              ],
              1
            )
          ],
          1
        )
      ]),
      _c("div", { staticClass: "avue-shade", on: { click: _vm.showCollapse } })
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }