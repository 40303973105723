var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "avue-top" }, [
    _c("div", { staticClass: "top-bar__left" }, [
      _vm.showCollapse
        ? _c("div", { staticClass: "avue-breadcrumb" }, [
            _c("i", {
              staticClass: "iconfont icon-caidanzuo",
              class: [{ "icon-caidanyou": _vm.isCollapse }],
              staticStyle: {
                "font-size": "25px !important",
                "line-height": "50px"
              },
              on: { click: _vm.setCollapse }
            })
          ])
        : _vm._e()
    ]),
    _c("h1", { staticClass: "top-bar__title" }, [
      _vm.showMenu
        ? _c("div", { staticClass: "top-bar__item top-bar__item--show" })
        : _vm._e(),
      _vm.showSearch
        ? _c("span", { staticClass: "top-bar__item" }, [_c("top-search")], 1)
        : _vm._e()
    ]),
    _c(
      "div",
      { staticClass: "top-bar__right" },
      [
        [
          _c(
            "el-select",
            {
              staticStyle: { width: "100px", "margin-right": "15px" },
              attrs: { size: "small" },
              on: { change: _vm.getValue },
              model: {
                value: _vm.langVal,
                callback: function($$v) {
                  _vm.langVal = $$v
                },
                expression: "langVal"
              }
            },
            _vm._l(_vm.language, function(item) {
              return _c("el-option", {
                key: item.value,
                attrs: { label: item.label, value: item.value }
              })
            }),
            1
          )
        ],
        _vm.showColor
          ? _c(
              "el-tooltip",
              {
                attrs: {
                  effect: "dark",
                  content: "主题色",
                  placement: "bottom"
                }
              },
              [
                _c(
                  "div",
                  { staticClass: "top-bar__item" },
                  [_c("top-color")],
                  1
                )
              ]
            )
          : _vm._e(),
        _vm.showDebug
          ? _c(
              "el-tooltip",
              {
                attrs: {
                  effect: "dark",
                  content: _vm.logsFlag
                    ? "没有错误日志"
                    : _vm.logsLen + "条错误日志",
                  placement: "bottom"
                }
              },
              [_c("div", { staticClass: "top-bar__item" })]
            )
          : _vm._e(),
        _vm.showLock
          ? _c(
              "el-tooltip",
              {
                attrs: { effect: "dark", content: "锁屏", placement: "bottom" }
              },
              [_c("div", { staticClass: "top-bar__item" }, [_c("top-lock")], 1)]
            )
          : _vm._e(),
        _vm.showTheme
          ? _c(
              "el-tooltip",
              {
                attrs: {
                  effect: "dark",
                  content: "特色主题",
                  placement: "bottom"
                }
              },
              [_c("div", { staticClass: "top-bar__item top-bar__item--show" })]
            )
          : _vm._e(),
        _vm.showFullScren
          ? _c(
              "el-tooltip",
              {
                attrs: {
                  effect: "dark",
                  content: _vm.isFullScren ? "退出全屏" : "全屏",
                  placement: "bottom"
                }
              },
              [
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value:
                          this.$route.name == "首页" ||
                          this.$route.name == "Real time production Kanban" ||
                          this.$route.name == "Typesetting Management" ||
                          this.$route.name == "Personnel ReplacementS" ||
                          this.$route.name == "Equipment Details" ||
                          this.$route.name == "Process Modification" ||
                          this.$route.name == "Operational Manage" ||
                          this.$route.name == "Home Page" ||
                          this.$route.name == "PVH Home Page" ||
                          this.$route.name == "TracingReport",
                        expression:
                          "this.$route.name =='首页'|| this.$route.name =='Real time production Kanban' || this.$route.name =='Typesetting Management'||this.$route.name =='Personnel ReplacementS'||this.$route.name =='Equipment Details'||this.$route.name =='Process Modification'||this.$route.name =='Operational Manage'||this.$route.name =='Home Page'||this.$route.name =='PVH Home Page'||this.$route.name =='TracingReport'"
                      }
                    ],
                    staticClass: "top-bar__item"
                  },
                  [
                    _c("i", {
                      class: _vm.isFullScren
                        ? "iconfont icon-tuichuquanping"
                        : "iconfont icon-caozuo_quanpingfangda",
                      on: { click: _vm.handleScreen }
                    })
                  ]
                )
              ]
            )
          : _vm._e(),
        _c(
          "el-tooltip",
          {
            attrs: { effect: "dark", content: "用户头像", placement: "bottom" }
          },
          [
            _c("img", {
              staticClass: "top-bar__img",
              attrs: {
                src:
                  "https://gravatar.zeruns.tech/avatar/" +
                  _vm.oidcUser.name +
                  "?s=64&d=identicon"
              }
            })
          ]
        ),
        _c(
          "el-dropdown",
          [
            _c("span", { staticClass: "el-dropdown-link" }, [
              _vm._v(
                "\n              " +
                  _vm._s(_vm.oidcUser.name) +
                  "\n              "
              ),
              _c("i", { staticClass: "el-icon-arrow-down el-icon--right" })
            ]),
            _c(
              "el-dropdown-menu",
              { attrs: { slot: "dropdown" }, slot: "dropdown" },
              [
                _c("el-dropdown-item", [
                  _c(
                    "a",
                    { attrs: { href: _vm.personalURL, target: "_blank" } },
                    [_vm._v(_vm._s(_vm.$t("personal Information")))]
                  )
                ]),
                _c(
                  "el-dropdown-item",
                  [
                    _c("router-link", { attrs: { to: "/company" } }, [
                      _vm._v(" " + _vm._s(_vm.$t("Change Company")))
                    ])
                  ],
                  1
                ),
                _c(
                  "el-dropdown-item",
                  [
                    _c("router-link", { attrs: { to: "/logout" } }, [
                      _vm._v(" " + _vm._s(_vm.$t("Exit System")))
                    ])
                  ],
                  1
                )
              ],
              1
            )
          ],
          1
        ),
        _c("top-setting")
      ],
      2
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }