// 配置编译环境和线上环境之间的切换
let baseUrl = '';
let CurrpReleaseSystem = '';
let oidcSettings;

let iconfontVersion = ['567566_qo5lxgtishg','2863793_rvjwze76bd'];
let iconfontUrl = `//at.alicdn.com/t/font_$key.css`;

const env = process.env

if (env.NODE_ENV == 'development') {
    baseUrl = `https://api.denseny.com/`; // 开发环境地址
   // baseUrl = `http://192.168.2.242:8022/`; // 开发环境地址
    CurrpReleaseSystem="MES";
    // oidcSettings = { "authority": "http://192.168.2.64:45310", "clientId": "textile_client", "redirectUri": "http://localhost:8080/oidc-callback.html", "popupRedirectUri": "http://localhost:8080/oidc-popup-callback.html", "responseType": "code", "scope": "openid profile roles textile_client_api offline_access", "automaticSilentRenew": true, "automaticSilentSignin": false, "silentRedirectUri": "http://localhost:8080/silent-renew-oidc.html", "post_logout_redirect_uri": "http://localhost:8080" }
    oidcSettings = { "authority": "https://account.denseny.com", "clientId": "textile_client", "redirectUri": "http://localhost:8080/oidc-callback.html", "popupRedirectUri": "http://localhost:8080/oidc-popup-callback.html", "responseType": "code", "scope": "openid profile roles textile_client_api offline_access", "automaticSilentRenew": true, "automaticSilentSignin": false, "silentRedirectUri": "http://localhost:8080/silent-renew-oidc.html", "post_logout_redirect_uri": "http://localhost:8080" }
} else if (env.NODE_ENV == 'hkdev') {
    // baseUrl = `http://192.168.2.16:8022/`; // 开发环境地址
    baseUrl = `https://api.spinise.com/`; // 开发环境地址
    CurrpReleaseSystem="PVH";
    oidcSettings = { "authority": "https://account.spinise.com", "monitorSession":false, "clientId": "textile_client", "redirectUri": "http://localhost:8080/oidc-callback.html", "popupRedirectUri": "http://localhost:8080/oidc-popup-callback.html", "responseType": "code", "scope": "openid profile roles textile_client_api offline_access", "automaticSilentRenew": true, "automaticSilentSignin": false, "silentRedirectUri": "http://localhost:8080/silent-renew-oidc.html", "post_logout_redirect_uri": "http://localhost:8080" }
} else if (env.NODE_ENV == 'sh' || env.NODE_ENV == 'production') {
    baseUrl = `https://api.denseny.com/`; //上海环境地址
    // baseUrl = `http://192.168.2.242:8022/`; // 开发环境地址
    CurrpReleaseSystem="MES";
    oidcSettings = { "authority": "https://account.denseny.com", "monitorSession":false, "clientId": "textile_client", "redirectUri": "https://www.denseny.com/oidc-callback.html", "popupRedirectUri": "https://www.denseny.com/oidc-popup-callback.html", "responseType": "code", "scope": "openid profile roles textile_client_api offline_access", "automaticSilentRenew": true, "automaticSilentSignin": false, "silentRedirectUri": "https://www.denseny.com/silent-renew-oidc.html", "post_logout_redirect_uri": "https://www.denseny.com"  }
} else if (env.NODE_ENV == 'hk') {
    baseUrl = `https://api.spinise.com/`; //香港环境地址
    CurrpReleaseSystem="PVH";
    oidcSettings = { "authority": "https://account.spinise.com", "monitorSession":false, "clientId": "textile_client", "redirectUri": "https://www.spinise.com/oidc-callback.html", "popupRedirectUri": "https://www.spinise.com/oidc-popup-callback.html", "responseType": "code", "scope": "openid profile roles textile_client_api offline_access", "automaticSilentRenew": true, "automaticSilentSignin": false, "silentRedirectUri": "https://www.spinise.com/silent-renew-oidc.html", "post_logout_redirect_uri": "https://www.spinise.com"  }
} else if (env.NODE_ENV == 'pvh_hk_product') {
    baseUrl = `https://api.spinise.com/`; //香港环境地址
    CurrpReleaseSystem="PVH";
    oidcSettings = { "authority": "https://account.spinise.com", "monitorSession":false, "clientId": "textile_client", "redirectUri": "https://pvh.spinise.com/oidc-callback.html", "popupRedirectUri": "https://pvh.spinise.com/oidc-popup-callback.html", "responseType": "code", "scope": "openid profile roles textile_client_api offline_access", "automaticSilentRenew": true, "automaticSilentSignin": false, "silentRedirectUri": "https://pvh.spinise.com/silent-renew-oidc.html", "post_logout_redirect_uri": "https://pvh.spinise.com"  }
} else if (env.NODE_ENV == 'pvh_hk_training') {
    baseUrl = `https://api-training.spinise.com/`; //香港环境地址
    CurrpReleaseSystem="PVH";
    oidcSettings = { "authority": "https://account-training.spinise.com", "monitorSession":false, "clientId": "textile_client", "redirectUri": "https://pvh-training.spinise.com/oidc-callback.html", "popupRedirectUri": "https://pvh-training.spinise.com/oidc-popup-callback.html", "responseType": "code", "scope": "openid profile roles textile_client_api offline_access", "automaticSilentRenew": true, "automaticSilentSignin": false, "silentRedirectUri": "https://pvh-training.spinise.com/silent-renew-oidc.html", "post_logout_redirect_uri": "https://pvh-training.spinise.com"  }
} else if (env.NODE_ENV == 'pvh_hk_test') {
    baseUrl = `https://api-test.spinise.com/`; //香港环境地址
    CurrpReleaseSystem="PVH";
    oidcSettings = { "authority": "https://account-test.spinise.com", "monitorSession":false, "clientId": "textile_client", "redirectUri": "https://pvh-test.spinise.com/oidc-callback.html", "popupRedirectUri": "https://pvh-test.spinise.com/oidc-popup-callback.html", "responseType": "code", "scope": "openid profile roles textile_client_api offline_access", "automaticSilentRenew": true, "automaticSilentSignin": false, "silentRedirectUri": "https://pvh-test.spinise.com/silent-renew-oidc.html", "post_logout_redirect_uri": "https://pvh-test.spinise.com"  }
} else if (env.NODE_ENV == 'mes_sh_product') {
    baseUrl = `https://api.denseny.com/`; //香港环境地址
    CurrpReleaseSystem="MES";
    oidcSettings = { "authority": "https://account.denseny.com", "monitorSession":false, "clientId": "textile_client", "redirectUri": "https://mes.denseny.com/oidc-callback.html", "popupRedirectUri": "https://mes.denseny.com/oidc-popup-callback.html", "responseType": "code", "scope": "openid profile roles textile_client_api offline_access", "automaticSilentRenew": true, "automaticSilentSignin": false, "silentRedirectUri": "https://mes.denseny.com/silent-renew-oidc.html", "post_logout_redirect_uri": "https://mes.denseny.com"  }
} else if (env.NODE_ENV == 'mes_sh_training') {
    baseUrl = `https://api-training.denseny.com/`; //香港环境地址
    CurrpReleaseSystem="MES";
    oidcSettings = { "authority": "https://account-training.denseny.com", "monitorSession":false, "clientId": "textile_client", "redirectUri": "https://mes-training.denseny.com/oidc-callback.html", "popupRedirectUri": "https://mes-training.denseny.com/oidc-popup-callback.html", "responseType": "code", "scope": "openid profile roles textile_client_api offline_access", "automaticSilentRenew": true, "automaticSilentSignin": false, "silentRedirectUri": "https://mes-training.denseny.com/silent-renew-oidc.html", "post_logout_redirect_uri": "https://mes-training.denseny.com"  }
} else if (env.NODE_ENV == 'mes_sh_test') {
    baseUrl = `https://api-test.denseny.com/`; //香港环境地址
    CurrpReleaseSystem="MES";
    oidcSettings = { "authority": "https://account-test.denseny.com", "monitorSession":false, "clientId": "textile_client", "redirectUri": "https://mes-test.denseny.com/oidc-callback.html", "popupRedirectUri": "https://mes-test.denseny.com/oidc-popup-callback.html", "responseType": "code", "scope": "openid profile roles textile_client_api offline_access", "automaticSilentRenew": true, "automaticSilentSignin": false, "silentRedirectUri": "https://mes-test.denseny.com/silent-renew-oidc.html", "post_logout_redirect_uri": "https://mes-test.denseny.com"  }
}  else if (env.NODE_ENV == 'pvh_sh_training') {
    baseUrl = `https://api-training.denseny.com/`; //香港环境地址
    CurrpReleaseSystem="PVH";
    oidcSettings = { "authority": "https://account-training.denseny.com", "monitorSession":false, "clientId": "textile_client", "redirectUri": "https://pvh-training.denseny.com/oidc-callback.html", "popupRedirectUri": "https://pvh-training.denseny.com/oidc-popup-callback.html", "responseType": "code", "scope": "openid profile roles textile_client_api offline_access", "automaticSilentRenew": true, "automaticSilentSignin": false, "silentRedirectUri": "https://pvh-training.denseny.com/silent-renew-oidc.html", "post_logout_redirect_uri": "https://pvh-training.denseny.com"  }
} else if (env.NODE_ENV == 'local') {
    baseUrl = `http://192.168.2.64:45302/`; // 开发环境地址
    CurrpReleaseSystem = "PVH";
    //oidcSettings = { "authority": "http://192.168.2.64:45310", "clientId": "textile_client", "redirectUri": "http://localhost:8080/oidc-callback.html", "popupRedirectUri": "http://localhost:8080/oidc-popup-callback.html", "responseType": "code", "scope": "openid profile roles textile_client_api offline_access", "automaticSilentRenew": true, "automaticSilentSignin": false, "silentRedirectUri": "http://localhost:8080/silent-renew-oidc.html", "post_logout_redirect_uri": "http://localhost:8080" }
    oidcSettings = { "authority": "http://192.168.2.64:45310", "monitorSession":false, "clientId": "textile_client", "redirectUri": "http://192.168.2.64:8088/oidc-callback.html", "popupRedirectUri": "http://192.168.2.64:8088/oidc-popup-callback.html", "responseType": "code", "scope": "openid profile roles textile_client_api offline_access", "automaticSilentRenew": true, "automaticSilentSignin": false, "silentRedirectUri": "http://192.168.2.64:8088/silent-renew-oidc.html", "post_logout_redirect_uri": "http://192.168.2.64:8088" }
}

let pvhApiBase = '';
if (env.NODE_ENV == 'local') {
     pvhApiBase = "http://192.168.2.64:45302/api/";
}
else {
    pvhApiBase = baseUrl + "tracing/api/";
    //let pvhApiBase = "http://192.168.2.64:45302/api/";
}
let codeUrl = `${baseUrl}/code`
let businessUrl = baseUrl + `process/`;
let userUrl = baseUrl + `process/`;
 // let pvhApiBase = "http://192.168.2.64:45302/api/";
let apiBase = businessUrl;


export {
    baseUrl,
    iconfontUrl,
    iconfontVersion,
    codeUrl,
    env,
    userUrl,
    businessUrl,
    apiBase,
	pvhApiBase,
	CurrpReleaseSystem,
    oidcSettings
}