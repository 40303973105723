var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-container",
    { staticStyle: { height: "100%", border: "1px solid #eee" } },
    [
      _c(
        "div",
        { staticClass: "avue-sidebar leftNav" },
        [
          _c(
            "div",
            {
              staticStyle: {
                position: "absolute",
                width: "100%",
                height: "60px",
                "line-height": "60px",
                "font-size": "32px",
                "font-weight": "700",
                padding: "0 20px",
                "z-index": "1",
                left: "0",
                top: "10px",
                "box-sizing": "border-box"
              }
            },
            [_vm._v("PVH")]
          ),
          _c(
            "el-scrollbar",
            { staticStyle: { height: "100%" } },
            _vm._l(_vm.setNav.leftNav, function(item, index) {
              return _c(
                "el-menu",
                {
                  key: index,
                  staticStyle: { width: "285px" },
                  attrs: {
                    "unique-opened": "",
                    router: "",
                    "default-active": _vm.$route.path,
                    "text-color": "#909399",
                    collapse: _vm.keyCollapse,
                    mode: "vertical",
                    "show-timeout": 200
                  },
                  on: { select: _vm.handleSelect }
                },
                [
                  _c(
                    item.children && item.children.length > 0
                      ? "el-submenu"
                      : "el-menu-item",
                    {
                      tag: "component",
                      staticClass: "menu-item",
                      attrs: { index: item.url }
                    },
                    [
                      _c("template", { slot: "title" }, [
                        _c("i", { class: [item.icon] }),
                        _c("span", [_vm._v(_vm._s(item.title))])
                      ]),
                      item.children && item.children.length > 0
                        ? _vm._l(item.children, function(v, i) {
                            return _c(
                              "el-menu-item",
                              {
                                key: v.url + i,
                                staticClass: "menu-item",
                                attrs: { index: v.url }
                              },
                              [
                                _c("i", { class: [v.icon] }),
                                _c(
                                  "span",
                                  { attrs: { slot: "title" }, slot: "title" },
                                  [_vm._v(_vm._s(v.title))]
                                )
                              ]
                            )
                          })
                        : _vm._e()
                    ],
                    2
                  )
                ],
                1
              )
            }),
            1
          )
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "rightCon",
          staticStyle: { width: "calc(100% - 220px)" }
        },
        [
          _c("div", { staticClass: "userInfo" }, [
            _c(
              "div",
              [
                [
                  _c("el-button", {
                    staticStyle: { "font-size": "18px" },
                    attrs: { type: "primary", icon: "iconfont icon-pvhn22" },
                    on: { click: _vm.setCollapse }
                  })
                ]
              ],
              2
            ),
            _c(
              "div",
              { staticStyle: { display: "flex", "align-items": "center" } },
              [
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "100px" },
                      on: { change: _vm.getValue },
                      model: {
                        value: _vm.value,
                        callback: function($$v) {
                          _vm.value = $$v
                        },
                        expression: "value"
                      }
                    },
                    _vm._l(_vm.language, function(item) {
                      return _c("el-option", {
                        key: item.value,
                        attrs: { label: item.label, value: item.value }
                      })
                    }),
                    1
                  )
                ],
                [
                  _c(
                    "div",
                    {
                      staticClass: "demo-type",
                      staticStyle: {
                        display: "flex",
                        "align-items": "center",
                        "margin-left": "20px"
                      }
                    },
                    [
                      _c("span", { staticStyle: { padding: "0 10px" } }, [
                        _vm._v("pvh")
                      ]),
                      _c(
                        "el-avatar",
                        {
                          attrs: { size: 40, src: "https://empty" },
                          on: { error: _vm.errorHandler }
                        },
                        [
                          _c("img", {
                            attrs: {
                              src:
                                "https://cube.elemecdn.com/e/fd/0fc7d20532fdaf769a25683617711png.png"
                            }
                          })
                        ]
                      )
                    ],
                    1
                  )
                ]
              ],
              2
            )
          ]),
          _c(
            "el-container",
            {
              staticStyle: {
                height: "100%",
                "overflow-x": "hidden",
                "overflow-y": "scroll",
                width: "100%",
                "padding-bottom": "70px"
              }
            },
            [
              _c(
                "el-main",
                {
                  staticClass: "pvhContent",
                  staticStyle: { padding: "20px 40px !important" }
                },
                [[_c("router-view")]],
                2
              )
            ],
            1
          )
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }