var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-popover",
    { attrs: { placement: "bottom", width: "400", trigger: "hover" } },
    [
      _c("avue-crud", { attrs: { data: _vm.data, option: _vm.option } }),
      _c(
        "router-link",
        {
          staticClass: "top-mag__menu",
          attrs: { to: { path: "/info/message" } }
        },
        [
          _c("el-button", { attrs: { type: "text", size: "mini" } }, [
            _vm._v("查看全部")
          ])
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "top-bar__item top-bar__item--show",
          attrs: { slot: "reference" },
          slot: "reference"
        },
        [
          _c("el-badge", { attrs: { "is-dot": "" } }, [
            _c("i", { staticClass: " el-icon-bell" })
          ])
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }