var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "span",
    {
      on: {
        click: function($event) {
          _vm.logsFlag ? "" : _vm.handleOpen()
        }
      }
    },
    [
      _c("el-badge", { attrs: { value: _vm.logsFlag ? "" : _vm.logsLen } }, [
        _c("i", { staticClass: "icon-bug" })
      ]),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "日志",
            fullscreen: "",
            visible: _vm.box,
            width: "100%",
            "append-to-body": ""
          },
          on: {
            "update:visible": function($event) {
              _vm.box = $event
            }
          }
        },
        [_c("logs")],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }