/**
 * 全站权限配置
 * 
 */
import router from './router/router'
import store from './store'

import { validatenull } from '@/util/validate'
import { getToken, getGrop } from '@/util/auth'
import NProgress from 'nprogress' // progress bar
import 'nprogress/nprogress.css' // progress bar style
NProgress.configure({ showSpinner: false });
const lockPage = store.getters.website.lockPage; //锁屏页
router.beforeEach((to, from, next) => {
    console.log(to);
    //缓冲设置
    if (to.meta.keepAlive === true && store.state.tags.tagList.some(ele => {
            return ele.value === to.fullPath;
        })) {
        to.meta.$keepAlive = true;
    } else {
        NProgress.start()
        if (to.meta.keepAlive === true && validatenull(to.meta.$keepAlive)) {
            to.meta.$keepAlive = true;
        } else {
            to.meta.$keepAlive = false;
        }
    }
    const meta = to.meta || {};

    let token = getToken();

    if (token && token.length > 0) {
        if (store.getters.isLock && to.path != lockPage) { //如果系统激活锁屏，全部跳转到锁屏页
            next({ path: lockPage })
        } else if (to.path === "/company") {
            next()
        } else {
            //如果组织机构为空则跳转到公司选择页
            let groupId = getGrop();
            if (groupId !== undefined && groupId.length > 0) {
                let userInfo = store.getters.userInfo;
                if (userInfo !== undefined && userInfo.username !== undefined) {
                    const value = to.query.src || to.fullPath;
                    const label = to.query.name || to.name;
                    if (meta.isTab !== false && !validatenull(value) && !validatenull(label)) {
                        store.commit('ADD_TAG', {
                            label: label,
                            value: value,
                            params: to.params,
                            query: to.query,
                            group: router.$avueRouter.group || []
                        });
                    }
                    next() 
                } else {
                    store.dispatch('GetUserInfo').then(() => {
                        next({...to, replace: true })
                    }).catch(() => {
                        store.dispatch('FedLogOut').then(() => {
                            next({ path: '/company' })
                        })
                    });
                }
            } else {
                next('/company')
            }
        }
    } else {
        //判断是否需要认证，没有登录访问去登录页
        if (meta.isAuth === false) {
            next()
        } else {
            next('/company')
        }
    }
})

router.afterEach(() => {
    NProgress.done();
    const title = store.getters.tag.label;
    //根据当前的标签也获取label的值动态设置浏览器标题
    router.$avueRouter.setTitle(title);
});