var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-dialog",
        {
          attrs: { title: "选择", visible: _vm.box, width: "50%" },
          on: {
            "update:visible": function($event) {
              _vm.box = $event
            }
          }
        },
        [
          _c(
            "el-radio-group",
            {
              staticClass: "list",
              model: {
                value: _vm.text,
                callback: function($$v) {
                  _vm.text = $$v
                },
                expression: "text"
              }
            },
            [
              _c(
                "el-row",
                { attrs: { span: 24 } },
                _vm._l(_vm.list, function(item, index) {
                  return _c(
                    "el-col",
                    { key: index, attrs: { md: 4, xs: 12, sm: 4 } },
                    [
                      _c("el-radio", { attrs: { label: item.value } }, [
                        _vm._v(_vm._s(item.name))
                      ])
                    ],
                    1
                  )
                }),
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c("span", [
        _c("i", { staticClass: "el-icon-view", on: { click: _vm.open } })
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }