import Layout from '@/page/index/'
export default [
    {
        path: '/tracingSearch',
        component: Layout,
        redirect: '/wel/tracingSearch',
        children: [{
            path: 'pvhIndex',
            name: 'PVH Home Page',
            component: () =>
                import( /* webpackChunkName: "views" */ '@/page/tracingSearch')
        }]
    },
    {
        path: '/visualData',
        component: Layout,
        redirect: '/wel/visualData',
        children: [{
            path: 'mesIndex',
            name: 'MES Home Page',
            component: () =>
                import( /* webpackChunkName: "views" */ '@/page/visualData')
        }]
    },
    {
        path: '/wel',
        component: Layout,
        redirect: '/wel/index',
        children: [{
            path: 'index',
            name: 'Home Page',
            component: () =>
                import( /* webpackChunkName: "views" */ '@/page/wel')
        }]
    },
    //{
    //    path: '/express',
    //    component: Layout,
    //    redirect: '/wel/express',
    //    children: [{
    //        path: 'index',
    //        name: 'Express',
    //        component: () =>
    //            import( /* webpackChunkName: "views" */ '@/page/express')
    //    }]
    //}, 
    {
        path: '/visualData',
        component: Layout,
        redirect: '/wel/visualData',
        children: [{
            path: 'index',
            name: 'Real time production Kanban',
            component: () =>
                import( /* webpackChunkName: "views" */ '@/page/visualData')
        }]
    },
    {
        path: '/tracingReport',
        component: Layout,
        redirect: '/wel/tracingReport',
        children: [{
            path: 'index',
            name: 'TracingReport',
            component: () =>
                import( /* webpackChunkName: "views" */ '@/page/tracingReport')
        }]
    },
    {
        path: '/info',
        component: Layout,
        redirect: '/info/index',
        children: [{
            path: 'index',
            name: 'personal Information',
            component: () =>
                import( /* webpackChunkName: "views" */ '@/views/info/index')
        }, {
            path: 'message',
            name: 'Message Center',
            component: () =>
                import( /* webpackChunkName: "views" */ '@/views/info/message')
        }]
    }, {
        path: '/advanced-router',
        component: Layout,
        children: [{
            path: 'mutative-detail/:id',
            name: 'Dynamic Routing Details Page',
            component: () =>
                import( /* webpackChunkName: "utils" */ '@/views/advanced-router/mutative-detail')
        }, {
            path: 'argument-detail',
            name: 'Parameter Routing Details Page',
            component: () =>
                import( /* webpackChunkName: "utils" */ '@/views/advanced-router/argument-detail')
        }]
    }, {
        path: '/basic',
        component: Layout,
        children: [{
            path: 'dic',
            name: 'Dictionary Manage',
            component: () =>
                import( /* webpackChunkName: "utils" */ '@/views/basis/dic')
        },
          {
             path: "tip",
             name: "Post Manage",
             component: () =>
                 import("@/views/basis/tip")
            },
            {
                path: "fault",
                name: "Fault Information",
                component: () =>
                    import("@/views/basis/fault")
            },
        {
            path: "sim",
            name: "Environmental Regionalization",
            component: () =>
                import("@/views/basis/sim")
        }, {
            path: "wkln",
            name: "Process Manage",
            component: () =>
                import("@/views/basis/wkln")
        }, {
            path: "math",
            name: "Unit Of Measurement",
            component: () =>
                import("@/views/basis/math")
        }, {
            path: 'timp',
            name: 'Team Manage',
            component: () =>
                import( /* webpackChunkName: "utils" */ '@/views/basis/timp')
        }, {
            path: 'workp',
            name: 'Workshop Manage',
            component: () =>
                import( /* webpackChunkName: "utils" */ '@/views/basis/workp')
        }, {
            path: "color",
            name: "Coil Color",
            component: () =>
                import("@/views/basis/color")
        }, {
            path: "warn",
            name: "Energy Consumption Warning Settings",
            component: () =>
                import("@/views/basis/warn")
        },  {
            name: "DeclarationField",
            path: "declareFieldList",
            icon: 'el-icon-document',
            component: () =>
                import( /* webpackChunkName: "utils" */ '@/views/basis/declareFieldList')
        }, {
            name: "DeclarationFile",
            path: "declareFileList",
            icon: 'el-icon-document',
            component: () =>
                import( /* webpackChunkName: "utils" */ '@/views/basis/declareFileList')
            },{
                name: "Enumeration Management",
                path: "enumeration",
                icon: 'el-icon-document',
                component: () =>
                    import( /* webpackChunkName: "utils" */ '@/views/basis/enumeration')
            },]
    }, {
        path: '/system',
        component: Layout,
        children: [ {
                name: "FieldSettingList",
                path: "fieldList",
                icon: 'el-icon-document',
                component: () =>
                    import( /* webpackChunkName: "utils" */ '@/views/system/fieldList')
            }, {
                name: "FileSettingList",
                path: "fileList",
                icon: 'el-icon-document',
                component: () =>
                    import( /* webpackChunkName: "utils" */ '@/views/system/fileList')
            },{
                path: "customRules",
                name: "Custom Rules",
                component: () => import("@/views/system/customRules")
            }, {
                path: "basicSettings",
                name: "Basic Settings",
                component: () => import("@/views/system/basicSettings")
            }]
    }, {
        path: '/peop',
        component: Layout,
        children: [{
            path: 'pepoleOrder',
            name: 'Personnel Manage',
            component: () =>
                import( /* webpackChunkName: "utils" */ '@/views/peop/pepoleOrder')
        }]
    }, {
        path: '/peop',
        component: Layout,
        children: [{
            path: 'userList',
            name: 'User Management',
            component: () =>
                import( /* webpackChunkName: "utils" */ '@/views/peop/userList')
        }]
    }, {
        path: '/peop',
        component: Layout,
        children: [{
            path: 'userPermission',
            name: 'User Permission',
            component: () =>
                import( /* webpackChunkName: "utils" */ '@/views/peop/userPermission')
        }]
    }, {
        path: '/peop',
        component: Layout,
        children: [{
            path: 'roleList',
            name: 'Role Management',
            component: () =>
                import( /* webpackChunkName: "utils" */ '@/views/peop/roleList')
        }]
    },
    {
        path: '/peop',
        component: Layout,
        children: [{
            path: 'rolePermission',
            name: 'Role Permission',
            component: () =>
                import( /* webpackChunkName: "utils" */ '@/views/peop/rolePermission')
        }]
    }, {
        path: '/peop',
        component: Layout,
        children: [{
            path: 'roleToUser',
            name: 'Role To User',
            component: () =>
                import( /* webpackChunkName: "utils" */ '@/views/peop/roleToUser')
        }]
    }, {
        path: '/peop',
        component: Layout,
        children: [{
            path: 'userToRole',
            name: 'User To Role',
            component: () =>
                import( /* webpackChunkName: "utils" */ '@/views/peop/userToRole')
        }]
    }, {
        path: '/peop',
        component: Layout,
        children: [{
            path: 'classp',
            name: 'Department Manage',
            component: () =>
                import( /* webpackChunkName: "utils" */ '@/views/peop/classp')
        }]
    }, {
        path: '/peop',
        component: Layout,
        children: [{
            path: 'tip',
            name: 'Post Manage',
            component: () =>
                import( /* webpackChunkName: "utils" */ '@/views/basis/tip')
        }]
    }, {
        path: '/mtr',
        component: Layout,
        children: [{
            path: 'mtrlist',
            name: 'Material Classification',
            component: () =>
                import( /* webpackChunkName: "utils" */ '@/views/mtr/mtrlist')
        }, {
            path: 'mtrD',
            name: 'Material File',
            component: () =>
                import( /* webpackChunkName: "utils" */ '@/views/mtr/mtrD')
        }, {
            path: "mtrDic",
            name: "Material Properties",
            component: () => import("@/views/mtr/mtrDic")
        }, {
            path: "workNo",
            name: "Batch Manage",
            component: () => import("@/views/mtr/workNo")
        }]
    },

    {
        path: '/searchDashboard',
        component: Layout,
        children: [{
            path: 'formStyle',
            name: 'formStyle',
            component: () =>
                import( /* webpackChunkName: "utils" */ '@/views/searchDashboard/formStyle')
        }, {
            path: 'refineStyle',
            name: 'Variety Segmentation',
            component: () =>
                import( /* webpackChunkName: "utils" */ '@/views/searchDashboard/refineStyle')
        }, {
            path: 'usersStyle',
            name: 'Users Report',
            component: () =>
                import( /* webpackChunkName: "utils" */ '@/views/searchDashboard/usersStyle')
        }, {
            path: "reportStyle",
            name: "Multifunctional Report",
            component: () => import("@/views/searchDashboard/reportStyle")
        },  {
            name: "TracingReport",
            path: "tracingReport",
            component: () => import("@/views/searchDashboard/tracingReport")
        }]
    },

    {
        path: '/listArray',
        component: Layout,
        children: [{
            path: 'listClass',
            name: 'Typesetting Management',
            component: () =>
                import( /* webpackChunkName: "utils" */ '@/views/listArray/listClass')
        }, {
            path: "machineSetting",
            name: "Personnel Machine SettingS",
            component: () =>
                import( /* webpackChunkName: "utils" */ '@/views/listArray/machineSetting')
        }, {
            path: "pepoleChange",
            name: "Personnel ReplacementS",
            component: () =>
                import( /* webpackChunkName: "utils" */ '@/views/listArray/pepoleChange')
        }]
    }, {
        path: "/device",
        name: "Device Manage",
        component: Layout,
        children: [
            {
                name: "Equipment Model",
                path: "deviceType",
                icon: 'el-icon-document',
                component: () =>
                    import( /* webpackChunkName: "utils" */ '@/views/device/deviceType')
            }, {
                name: "Equipment Files",
                path: "index",
                icon: 'el-icon-document',
                component: () =>
                    import( /* webpackChunkName: "utils" */ '@/views/device/index')
            }, {
                name: "Equipment Details",
                path: "devFile",
                icon: 'el-icon-document',
                component: () =>
                    import( /* webpackChunkName: "utils" */ '@/views/device/devFile')
            }, {
                path: "maintain",
                name: "Maintenance",
                component: () =>
                    import("@/views/basis/maintain")
            }, {
                path: "warnList",
                name: "Fault Manage",
                component: () =>
                    import("@/views/basis/warnList")
            }, {
                name: "Object Model Manage",
                path: "thingsModel",
                icon: 'el-icon-document',
                component: () =>
                    import( /* webpackChunkName: "utils" */ '@/views/device/thingsModel')
            },
            {
                name: "Function Definition",
                path: "indexFunction",
                icon: 'el-icon-document',
                component: () =>
                    import( /* webpackChunkName: "utils" */ '@/views/device/indexFunction')
            }
        ]
    }, {
        path: "/contentCoupletSet",
        name: "Internet Of Things Settings",
        component: Layout,
        children: []
    },
    // 新添加的测试
    {
        path: "/pess",
        name: "Setting",
        component: Layout,
        children: [{
            name: "Test One",
            path: "oneindex",
            icon: 'el-icon-document',
            component: () =>
                import( /* webpackChunkName: "utils" */ '@/views/pess/oneindex')
        }, {
            name: "Test Two",
            path: "twoindex",
            icon: 'el-icon-document',
            component: () =>
                import( /* webpackChunkName: "utils" */ '@/views/pess/twoindex')
        }]
    },
    {
        path: "/wkln",
        name: "Processs Manage",
        component: Layout,
        children: [{
            name: "Standard Process Sheet",
            path: "standardList",
            icon: 'el-icon-document',
            component: () =>
                import( /* webpackChunkName: "utils" */ '@/views/wkln/standardList')
        }, {
            name: "Planned process sheet",
            path: "planList",
            icon: 'el-icon-document',
            component: () =>
                import( /* webpackChunkName: "utils" */ '@/views/wkln/planList')
        }, {
            name: "Actual Process Sheet",
            path: "realityList",
            icon: 'el-icon-document',
            component: () =>
                import( /* webpackChunkName: "utils" */ '@/views/wkln/realityList')
        }, {
            name: "Process Project",
            path: "wklnObject",
            icon: 'el-icon-document',
            component: () =>
                import( /* webpackChunkName: "utils" */ '@/views/wkln/wklnObject')
        },{
            name: "Process Modification",
            path: "wklnChange",
            icon: 'el-icon-document',
            component: () =>
                import( /* webpackChunkName: "utils" */ '@/views/wkln/wklnChange')
        }, {
            name: "Add Standard Process Sheet",
            path: "addstandard",
            icon: 'el-icon-document',
            component: () =>
                import( /* webpackChunkName: "utils" */ '@/views/wkln/addstandard')
        },{
            name: "Add planned process sheet",
            path: "addPlanList",
            icon: 'el-icon-document',
            component: () =>
                import( /* webpackChunkName: "utils" */ '@/views/wkln/addPlanList')
        }, {
            name: "Add Actual Process Order",
            path: "addreality",
            icon: 'el-icon-document',
            component: () =>
                import( /* webpackChunkName: "utils" */ '@/views/wkln/addreality')
        }, {
            name: "Edit Standard Process Sheet",
            path: "editstandrd",
            icon: 'el-icon-document',
            component: () =>
                import( /* webpackChunkName: "utils" */ '@/views/wkln/editStandard')
        }, {
            name: "Edit actual Process Sheet",
            path: "editreality",
            icon: 'el-icon-document',
            component: () =>
                import( /* webpackChunkName: "utils" */ '@/views/wkln/editReality')
        }, {
            name: "Edit planned process sheet",
            path: "editPlanList",
            icon: 'el-icon-document',
            component: () =>
                import( /* webpackChunkName: "utils" */ '@/views/wkln/editPlanList')
        }, {
            name: "Standard Process Sheet Details",
            path: "lookstandrd",
            icon: 'el-icon-document',
            component: () =>
                import( /* webpackChunkName: "utils" */ '@/views/wkln/lookStandard')
        }, {
            name: "Actual Process Sheet Details",
            path: "lookreality",
            icon: 'el-icon-document',
            component: () =>
                import( /* webpackChunkName: "utils" */ '@/views/wkln/lookReality')
        }]
    }, {
        path: "/productionManagement",
        name: "Production Manage",
        redirect: "/additionToMachine",
        component: Layout,
        children: [{
            name: "Operational Manage",
            path: "additionToMachine",
            icon: 'el-icon-document',
            component: () =>
                import( /* webpackChunkName: "utils" */ '@/views/productionManagement/additionToMachine')
        }]
    }, {
        path: "/pvh_factory",
        name: "Spinning Mill",
        redirect: "/materialOrd",
        component: Layout,
        children: [{
                name: "RawMaterialPurchase",
                path: "materialOrd",
                icon: 'el-icon-document',
                component: () =>
                    import( /* webpackChunkName: "utils" */ '@/views/pvh_factory/materialOrd')
            }, {
                name: "Information Declaration",
                path: "declarationEntry",
                icon: 'el-icon-document',
                component: () =>
                    import( /* webpackChunkName: "utils" */ '@/views/pvh_factory/declarationEntry')
            }, {
                name: "Purchase-Order List",
                path: "purchaseOrd",
                icon: 'el-icon-document',
                component: () =>
                    import( /* webpackChunkName: "utils" */ '@/views/pvh_factory/purchaseOrd')
            }, {
                name: "Sale-Order List",
                path: "salesOrd",
                icon: 'el-icon-document',
                component: () =>
                    import( /* webpackChunkName: "utils" */ '@/views/pvh_factory/salesOrd')
            },  {
                name: "Purchase-Order Info",
                path: "purchaseAdd",
                icon: 'el-icon-document',
                meta: {
                    keepAlive: true, //此组件需要被缓存
                },
                component: () =>
                    import('@/views/pvh_factory/purchaseAdd')
            },
            {
                name: "PO AGENT INFO",
                path: "purchaseAdd_AgenEntryPo",
                icon: 'el-icon-document',
                component: () =>
                    import('@/views/pvh_factory/purchaseAdd_AgenEntryPo')
            },{
                name: "ADD Contract",
                path: "contractAdd",
                icon: 'el-icon-document',
                meta: {
                    keepAlive: true, //此组件需要被缓存
                },
                component: () =>
                    import('@/views/pvh_factory/contractAdd')
            }, {
                name: "ContractManagement",
                path: "contractList",
                icon: 'el-icon-document',
                component: () =>
                    import('@/views/pvh_factory/contractList')
            }
        ]
    },
    {
        path: "/pvh_operation",
        name: "Operation Interface",
        redirect: "/orderFracing",
        component: Layout,
        children: [{
            name: "OrderTraceability",
            path: "orderFracing",
            icon: 'el-icon-document',
            component: () =>
                import( /* webpackChunkName: "utils" */ '@/views/pvh_operation/orderFracing')
        }, {
                name: "pdfLog",
                path: "pdfLog",
                icon: 'el-icon-document',
                component: () =>
                    import( /* webpackChunkName: "utils" */ '@/views/pvh_operation/pdfLog')
            }, {
            name: "review",
            path: "review",
            icon: 'el-icon-document',
            component: () =>
                import( /* webpackChunkName: "utils" */ '@/views/pvh_operation/review')
        },
        {
            name: "loadSpeed",
            path: "loadSpeed",
            icon: 'el-icon-document',
            meta: {
                isTab: false, //此组件需要被缓存
            },
            component: () =>
                import( /* webpackChunkName: "utils" */ '@/views/pvh_operation/loadSpeed')
        },
        {
            name: "PRODUCT TREE TRACING",
            path: "orderTree",
            icon: 'el-icon-document',
            component: () =>
                import( /* webpackChunkName: "utils" */ '@/views/pvh_operation/orderTree')
        },
        ]
    },
    {
        path: "/pvh_search",
        name: "Product tracing",
        redirect: "/tracingSearch",
        component: Layout,
        children: [{
            name: "Product tracing",
            path: "tracingSearch",
            icon: 'el-icon-s-help',
            component: () =>
                import( /* webpackChunkName: "utils" */ '@/views/pvh_search/tracingSearch')
        },
        {
            name: "ListModel",
            path: "ListModel",
            icon: 'el-icon-s-help',
            component: () =>
                import( /* webpackChunkName: "utils" */ '@/views/pvh_search/ListModel')
        }, {
            name: "SubjectList",
            path: "subjectList",
            icon: 'el-icon-document',
            component: () =>
                import( /* webpackChunkName: "utils" */ '@/views/pvh_search/subjectList')
        },
        ]
    },
    {
        path: "/pvh_admin",
        name: "Admin Management",
        redirect: "/qrCodeRecode",
        component: Layout,
        children: [{
            name: "QR Code Access Record",
            path: "qrCodeRecode",
            icon: 'el-icon-s-help',
            component: () =>
                import( /* webpackChunkName: "utils" */ '@/views/pvh_admin/qrCodeRecode')
        }, 
        {
            name: "QrCodeJump",
            path: "qrJump",
            icon: 'el-icon-document',
            component: () =>
                import( /* webpackChunkName: "utils" */ '@/views/pvh_admin/qrJump')
            },
            {
                name: "LogManagement",
                path: "logList",
                icon: 'el-icon-document',
                component: () =>
                    import( /* webpackChunkName: "utils" */ '@/views/pvh_admin/logList')
            },
            {
                name: "ApiLogList",
                path: "apiLogList",
                icon: 'el-icon-document',
                component: () =>
                    import( /* webpackChunkName: "utils" */ '@/views/pvh_admin/apiLogList')
            },
            {
                name: "AuditLogList",
                path: "auditLogList",
                icon: 'el-icon-document',
                component: () =>
                    import( /* webpackChunkName: "utils" */ '@/views/pvh_admin/auditLogList')
            },
        ]
    },
    {
        path: "/pvh_setting",
        name: "Basic Settings",
        redirect: "/dashboard",
        component: Layout,
        children: [{
            name: "DashboardS",
            path: "dashboard",
            icon: 'el-icon-document',
            component: () =>
                import( /* webpackChunkName: "utils" */ '@/views/pvh_setting/dashboard')
        }, {
            name: "SupplierSectionList",
            path: "supplierList",
            icon: 'el-icon-document',
            component: () =>
                import( /* webpackChunkName: "utils" */ '@/views/pvh_setting/supplierList')
        }, {
            name: "SupplierSection",
            path: "supplierAdd",
            icon: 'el-icon-document',
            component: () =>
                import( /* webpackChunkName: "utils" */ '@/views/pvh_setting/supplierAdd')
        }, {
            name: "VendorCertifiedFactory",
            path: "supplierCer",
            icon: 'el-icon-document',
            component: () =>
                import( /* webpackChunkName: "utils" */ '@/views/pvh_setting/supplierCer')
        }, {
            name: "Affiliated Corporation",
            path: "affiliatedCorp",
            icon: 'el-icon-document',
            component: () =>
                import( /* webpackChunkName: "utils" */ '@/views/pvh_setting/affiliatedCorp')
        },
        {
            name: "AddressSetting",
            path: "vendorAddress",
            icon: 'el-icon-document',
            component: () =>
                import( /* webpackChunkName: "utils" */ '@/views/pvh_setting/vendorAddress')
        },]
    },
    {
        path: "/userCenter",
        name: "User Center",
        redirect: "tokenManagement",
        component: Layout,
        children: [ 
             {
                path: "tokenManagement",
                name: "Token Management",
                component: () =>
                    import("@/views/userCenter/tokenManagement")
            },
        ]
    },
]
