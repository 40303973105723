import { CurrpReleaseSystem } from "@/config/env";
/**
 * 全局配置文件
 */
export default {
    title: "Avue",
    logo: "P",
    indexTitle: 'PVH Trace System',
    lockPage: '/lock',
    tokenTime: 6000,
    info: {
        title: "数字化生产管理系统",
        list: [
            '人员部门信息管理，对公司员工信息作出管理',
            '物料档案批号管理，对物料生产批号信息作出管理',
            '机台报表智能分析，对生产数据进行日/月统计分析',
        ]
    },
    //http的status默认放行不才用统一处理的,
    statusWhiteList: [400, 404, 301, 302, 204, 101],
    //配置首页不可关闭
    isFirstPage: false,
    fistPage: {
        label: "PVH Home Page",
        value: "/tracingSearch/pvhIndex",
        params: {},
        query: {},
        group: [],
        close: false
    },
    //配置菜单的属性
    menu: {
        props: {
            label: 'label',
            path: 'path',
            icon: 'icon',
            children: 'children'
        }
    }
}