import Mock from 'mockjs'
import { CurrpReleaseSystem } from "@/config/env";
//import i18n from '@/languages'
/**
 * 
 * 动态模拟菜单
 * 
 * label菜单的名字
 * path菜单的路径
 * icon菜单的图标（系统采用的阿里巴巴图表库）
 * compnent组件的地址
 * children子类菜单数组
 * group配置其他路由激活菜单高亮
 */
var top = [
    {
        label: "Real time production  Data",
        path: '/visualData',
        icon: 'el-icon-menu',
        parentId: 0
    },
    {
        label: "设置",
        icon: 'el-icon-setting',
        href: '/dev/index',
        parentId: 1
    },
    {
        label: "官网",
        icon: 'el-icon-document',
        href: "https://avuejs.com",
        parentId: 3
    }
]
const first = [
    {
        id: 101,
        label: "Real time production Kanban",
        path: '/visualData',
        component: 'views/guide/index',
        icon: 'iconfont icon-dashboard',
        children: [],
    },
    {
        id: 101,
        label: 'tracingSearch',
        path: '/tracingSearch',
        icon: 'el-icon-data-line',
        meta: {},
        children: []
    },
    {
        id: 102,
        label: 'Product tracing',
        path: '/pvh_search',
        icon: 'iconfont icon-hudongguanli',
        meta: {},
        children: [{
            id: 109,
            label: 'Product tracing',
            path: "tracingSearch",
            children: [],
        },]
    },
    {
        id: 103,
        label: 'Vendor',
        path: '/pvh_setting',
        icon: 'iconfont icon-dianpu',
        meta: {},
        children: [
            {
                id: 105,
                label: 'VENDOR SECTION',
                path: "supplierList",
                children: [],
            },
            {
                id: 104,
                label: 'Certified factory',
                path: "supplierCer",
                children: [],
            },

            {
                id: 108,
                label: 'Affiliated Corporation',
                path: "affiliatedCorp",
                children: [],
            },
            {
                id: 106,
                label: 'DeclarationField',
                path: "declareFieldList",
                children: [],
            },
            {
                id: 107,
                label: 'DeclarationFile',
                path: "declareFileList",
                children: [],
            },
        ]
    },
    {
        id: 107,
        label: 'Orders',
        path: '/pvh_factory',
        icon: 'iconfont icon-dingdan1',
        meta: {},
        children: [
            {
                id: 109,
                label: 'Purchase Order',
                path: "purchaseOrd",
                children: [],
            },
            {
                id: 110,
                label: 'Sales Order',
                path: "salesOrd",
                children: [],
            }
            ,
            {
                id: 108,
                label: 'ContractManagement',
                path: "contractList",
                children: [],
            },
            {
                id: 112,
                label: 'BusinessSalesOrd',
                path: "businessSalesOrd",
                children: []
            }
        ]
    },
    {
        id: 108,
        label: 'Admin Management',
        path: '/pvh_admin',
        icon: 'el-icon-s-opportunity',
        meta: {},
        children: [
            {
                id: 106,
                label: 'FieldSetting',
                path: "fieldList",
                children: [],
            },
            {
                id: 107,
                label: 'FileSetting',
                path: "fileList",
                children: [],
            },
            {
                id: 108,
                label: 'AddressSetting',
                path: "vendorAddress",
                children: [],
            },
            {
                id: 119,
                label: 'QR Code Access Record',
                path: "qrCodeRecode",
                children: [],
            },
            {
                id: 120,
                label: 'Subject',
                path: "subjectList",
                children: [],
            }, {
                id: 121,
                label: 'Enumeration Management',
                path: "enumeration",
                children: [],
            },
            {
                id: 122,
                label: 'LogManagement',
                path: "logList",
                children: [],
            },        ]
    },
    //{
    //    id: 110,
    //    label: 'User/Role',
    //    path: '/',
    //    icon: 'iconfont icon-jiaoseleixing',
    //    meta: {},
    //    children: []
    //},
    //{
    //    id: 33,
    //    label: "Home Page",
    //    path: '/wel',
    //    component: 'views/guide/index',
    //    icon: 'icon-canshu',
    //    children: [],
    //},
    //{
    //    id: 33,
    //    label: "Express",
    //    path: '/express',
    //    component: 'views/guide/index',
    //    icon: 'icon-canshu',
    //    children: [],
    //},
    {
        id: 432,
        label: "User Center",
        path: '/',
        icon: 'el-icon-user-solid',
        children: [
            {
                id: 99,
                path: "https://account.denseny.com/Manage?target=inIframe",
                label: "My profile",
                children: [],
            },
            {
                id: 100,
                path: "https://account.denseny.com/Manage/PersonalData?target=inIframe",
                label: "My personal data",
                children: [],
            },
            {
                id: 101,
                path: "https://account.denseny.com/Manage/TwoFactorAuthentication?target=inIframe",
                label: "Two-factor authentication",
                children: [],
            },
            {
                id: 102,
                path: "https://account.denseny.com/Manage/ChangePassword?target=inIframe",
                label: "Change password",
                children: [],
            },
            {
                id: 5,
                path: "https://account.denseny.com/Manage/TokenManagement?target=inIframe",
                label: "Token Management",
                children: [],
            },
        ]
    },
    {
        id: 2,
        label: "System Manage",
        path: '/system',
        icon: 'el-icon-s-tools',
        meta: {},
        children: [
            {
                id: 3,
                label: "Basic Settings",
                path: "basicSettings",
                children: []
            }
            // 公司管理
            // {
            //     id: 3,
            //     label: "Company Manage",
            //     path:"grop",
            //     children: [],
            // },
            /*{
                id: 4,
                label: "用户管理",
                icon: 'icon-souhu',
                children: [],
            }, 
            {
                id: 5,
                label: "角色管理",
                icon: 'icon-msnui-360',
                children: [],
            }, 
            {
                id: 5,
                label: "权限管理",
                icon: 'icon-msnui-360',
                children: [],
            }, 
            {
                id: 5,
                label: "服务配置",
                icon: 'icon-msnui-360',
                children: [],
            }, 
            {
                id: 5,
                label: "系统配置",
                icon: 'icon-msnui-360',
                children: [],
            }, 
            {
                id: 5,
                label: "参数配置",
                icon: 'icon-msnui-360',
                children: [],
            }, 
            {
                id: 5,
                label: "通知公告",
                icon: 'icon-msnui-360',
                children: [],
            }*/
        ]
    },

    // {
    //   id: 13,
    //   label: "权限测试页",
    //   path: '/role',
    //   icon: 'icon-quanxian',
    //   component: 'views/role',
    //   children: [],
    // },

    {
        id: 5,
        label: "Material Manage",
        path: '/mtr',
        icon: 'icon-iframe',
        meta: {},
        children: [
            {
                id: 34,
                label: "Material Classification",
                path: "mtrlist",
                children: [],
            },
            {
                id: 33,
                label: "Material Properties",
                path: "mtrDic",
                children: [],
            },
            {
                id: 35,
                label: "Batch Manage",
                path: "workNo",
                children: [],
            },
            {
                id: 36,
                label: "Material File",
                path: "mtrD",
                children: [],
            }
        ]
    },
    {
        id: 7,
        label: "Basic Data",
        path: '/basic',
        icon: 'el-icon-coin',
        meta: {},
        children: [
            {
                id: 3,
                label: "Dictionary Manage",
                path: "dic",
                children: [],
            },
            // 岗位管理放到人员管理里
            // {
            //     id: 3,
            //     label: "Post Manage",
            //     path: "tip",
            //     children: [],
            // },
            {
                id: 4,
                label: "Environmental Regionalization",
                path: "sim",
                children: [],
            },
            {
                id: 5,
                label: "Process Manage",
                path: "wkln",
                children: [],
            },
            {
                id: 5,
                label: "Unit Of Measurement",
                path: "math",
                children: [],
            },
            {
                id: 5,
                label: "Team Manage",
                path: "timp",
                children: [],
            },
            {
                id: 5,
                label: "Workshop Manage",
                path: "workp",
                children: [],
            },
            {
                id: 5,
                label: "Coil Color",
                path: "color",
                children: [],
            },
            {
                id: 5,
                label: "Energy Consumption Warning Settings",
                path: "warn",
                children: [],
            },
            {
                id: 5,
                label: "Fault Information",
                path: "fault",
                children: [],
            }
        ]
    },

    {
        id: 5,
        label: "Personnel Manage",
        path: '/peop',
        icon: 'el-icon-user-solid',
        meta: {},
        children: [
            {
                id: 30,
                path: "pepoleOrder",
                label: "Staff Manage",
                children: [],
            },
            {
                id: 29,
                label: "Post Manage",
                path: "tip",
                children: [],
            },
            {
                id: 31,
                label: "Department Manage",
                path: "classp",
                children: [],
            },
            {
                id: 32,
                path: "userList",
                label: "User Management",
                children: [],
            },
              {
                id: 34,
                path: "roleList",
                label: "Role Management",
                children: [],
            },
        ]
    },
    {
        id: 5,
        label: "Device Manage",
        path: "/device",
        icon: 'el-icon-s-ticket',
        meta: {},
        children: [

            {
                id: 38,
                label: "Equipment Model",
                path: "deviceType",
                children: [],
            },
            {
                id: 37,
                label: "Equipment Files",
                path: "index",
                children: [],
            },
            {
                id: 40,
                label: "Equipment Details",
                path: "devFile",
                children: [],
            },
            {
                id: 6,
                label: "Maintenance",
                path: "maintain",
                children: [],
            },
            {
                id: 7,
                label: "Fault Manage",
                path: "warnList",
                children: [],
            }
            /* {
                id: 39,
                label: "设备明细中心",
                path: "deviceMonitor",
                icon: 'icon-baidu1',
                children: [],
            }*/
        ]
    },
    {
        id: 5,
        label: "Internet Of Things Settings",
        path: "/contentCoupletSet",
        icon: 'el-icon-connection',
        meta: {},
        children: [
            {
                id: 37,
                label: "Function Definition",
                path: "index",
                children: [],
            },
            {
                id: 39,
                label: "Object Model Manage",
                path: "thingsModel",
                children: [],
            }
        ]
    },
    //  新添加的测试
    {
        id: 5,
        label: "Test Setting",
        path: "/pess",
        icon: 'el-icon-connection',
        meta: {},
        children: [
            {
                id: 37,
                label: "Test One",
                path: "oneindex",
                children: [],
            },
            {
                id: 37,
                label: "Test Two",
                path: "twoindex",
                children: [],
            },
        ]
    },
    {
        id: 5,
        label: "Workforce Manage",
        path: '/listArray',
        icon: 'el-icon-date',
        meta: {},
        children: [
            {
                id: 30,
                label: "Workforce Manage",
                path: "listClass",
                children: [],
            },
            {
                id: 40,
                label: "Personnel Machine Setting",
                path: "machineSetting",
                children: [],
            },
            {
                id: 40,
                label: "Personnel Replacement",
                path: "pepoleChange",
                children: [],
            },
            /*{
                id: 41,
                label: "班次机台调整",
                icon: 'icon-baidu1',
                children: [],
            }, 
            {
                id: 42,
                label: "班制管理",
                icon: 'icon-baidu1',
                children: [],
            }*/
        ]
    },
    /*{
        id: 5,
        label: "行驶平台",
        path: '/mtr',
        icon: 'el-icon-circle-check',
        meta: {},
        children: [
            {
                id: 43,
                label: "细纱看板",
                icon: 'el-icon-document',
                children: [],
            }
        ]
    },*/
    {
        id: 5,
        label: "Processs Manage",
        path: '/wkln',
        icon: 'el-icon-printer',
        meta: {},
        children: [
            /*{
                id: 44,
                label: "品种工艺单",
                icon: 'el-icon-document',
                children: [],
            }, */
            {
                id: 45,
                label: "Process Project",
                path: "wklnObject",
                children: [],
            },
            {
                label: "Process Modification",
                path: "wklnChange",
                children: []
            },
            {
                label: "Standard Process Sheet",
                path: "standardList",
                children: []
            },
            {
                label: "Standard Process Sheet",
                path: "planList",
                children: []
            },
            {
                label: "Actual Process Sheet",
                path: "realityList",
                children: []
            }
        ]
    },
    {
        id: 5,
        label: "Production Manage",
        path: '/productionManagement',
        icon: 'el-icon-help',
        meta: {},
        children: [
            {
                label: "Operational Manage",
                path: "additionToMachine",
                children: []
            }
        ]
    },
    /*{
        id: 5,
        label: "生产管理",
        path: '/pro',
        icon: 'el-icon-s-help',
        meta: {},
        children: [
            {
                id: 46,
                label: "生产排产",
                icon: 'el-icon-document',
                children: [],
            }, 
            {
                id: 47,
                label: "生产接批",
                icon: 'icon-baidu1',
                children: [],
            }, 
            {
                id: 48,
                label: "翻改任务",
                icon: 'icon-baidu1',
                children: [],
            }, 
            {
                id: 49,
                label: "了机任务",
                icon: 'icon-baidu1',
                children: [],
            }, 
            {
                id: 50,
                label: "开台列表",
                icon: 'icon-baidu1',
                children: [],
            }, 
            {
                id: 51,
                label: "了机列表",
                icon: 'icon-baidu1',
                children: [],
            }, 
            {
                id: 52,
                label: "品种开台跟踪",
                icon: 'icon-baidu1',
                children: [],
            }, 
            {
                id: 53,
                label: "生产报工",
                icon: 'icon-baidu1',
                children: [],
            }
        ]
    },
    {
        id: 54,
        label: "智能分析",
        path: '/ai',
        icon: 'el-icon-camera',
        meta: {},
        children: [
            {
                id: 55,
                label: "产量能耗报表",
                icon: 'el-icon-document',
                children: [],
            }, 
            {
                id: 56,
                label: "机台能耗报表",
                icon: 'icon-baidu1',
                children: [],
            }
        ]
    }*/

    {
        id: 5,
        label: "Search Dashboard",
        path: '/searchDashboard',
        icon: 'el-icon-data-analysis',
        meta: {},
        children: [
            {
                id: 57,
                label: "formStyle",
                path: "formStyle",
                children: [],
            },
            {
                id: 58,
                label: "Variety Segmentation",
                path: "refineStyle",
                children: [],
            },
            {
                id: 59,
                label: "Users Report",
                path: "usersStyle",
                children: [],
            },
            {
                id: 60,
                label: "Multifunctional Report",
                path: "reportStyle",
                children: [],
            }, {
                id: 60,
                label: "Custom Rules",
                path: "customRules",
                children: [],
            }
        ]
    }

]
const PVHfirst = [
    {
        id: 101,
        label: 'TracingReport',
        path: '/tracingReport',
        icon: 'el-icon-data-line',
        meta: {},
        children: []
    },
    {
        id: 102,
        label: 'Product tracing',
        path: '/pvh_search',
        icon: 'iconfont icon-hudongguanli',
        meta: {},
        children: [{
            id: 109,
            label: 'Product tracing',
            path: "tracingSearch",
            children: [],
        },]
    },
    {
        id: 103,
        label: 'Vendor',
        path: '/pvh_setting',
        icon: 'iconfont icon-dianpu',
        meta: {},
        children: [
            {
                id: 105,
                label: 'VENDOR SECTION',
                path: "supplierList",
                children: [],
            },
            {
                id: 104,
                label: 'Certified factory',
                path: "supplierCer",
                children: [],
            },

            {
                id: 108,
                label: 'Affiliated Corporation',
                path: "affiliatedCorp",
                children: [],
            },
            {
                id: 106,
                label: 'DeclarationField',
                path: "declareFieldList",
                children: [],
            },
            {
                id: 107,
                label: 'DeclarationFile',
                path: "declareFileList",
                children: [],
            },
        ]
    },
    {
        id: 107,
        label: 'Orders',
        path: '/pvh_factory',
        icon: 'iconfont icon-dingdan1',
        meta: {},
        children: [
            {
                id: 109,
                label: 'Purchase Order',
                path: "purchaseOrd",
                children: [],
            },
            {
                id: 110,
                label: 'Sales Order',
                path: "salesOrd",
                children: [],
            }
            ,
            {
                id: 108,
                label: 'ContractManagement',
                path: "contractList",
                children: [],
            },
            {
                id: 112,
                label: 'BusinessSalesOrd',
                path: "businessSalesOrd",
                children: []
            }
        ]
    },
    {
        id: 7,
        label: "Basic Data",
        path: '/basic',
        icon: 'el-icon-coin',
        meta: {},
        children: [
            {
                id: 3,
                label: "Dictionary Manage",
                path: "dic",
                children: [],
            },
            {
                id: 5,
                label: "Unit Of Measurement",
                path: "math",
                children: [],
            },
            {
                id: 5,
                label: "Token Management",
                path: "tokenManagement",
                children: [],
            },
        ]
    },
    {
        id: 5,
        label: "Material Manage",
        path: '/mtr',
        icon: 'icon-iframe',
        meta: {},
        children: [
            {
                id: 34,
                label: "Material Classification",
                path: "mtrlist",
                children: [],
            },
            {
                id: 36,
                label: "Material File",
                path: "mtrD",
                children: [],
            }
        ]
    },
    {
        id: 6,
        label: "Personnel Manage",
        path: '/peop',
        icon: 'icon-textile-shequn',
        meta: {},
        children: [
            {
                id: 30,
                path: "pepoleOrder",
                label: "Staff Manage",
                children: [],
            },
            {
                id: 31,
                label: "Department Manage",
                path: "classp",
                children: [],
            },
            {
                id: 34,
                path: "roleList",
                label: "Role Management",
                children: [],
            },
            {
                id: 32,
                path: "userList",
                label: "User Management",
                children: [],
            }
        ]
    },
    {
        id: 432,
        label: "User Center",
        path: '/',
        icon: 'el-icon-user',
        children: [
            {
                id: 99,
                path: "https://account.spinise.com/Manage?target=inIframe",
                label: "My profile",
                children: [],
            },
            {
                id: 100,
                path: "https://account.spinise.com/Manage/PersonalData?target=inIframe",
                label: "My personal data",
                children: [],
            },
            {
                id: 101,
                path: "https://account.spinise.com/Manage/TwoFactorAuthentication?target=inIframe",
                label: "Two-factor authentication",
                children: [],
            },
            {
                id: 102,
                path: "https://account.spinise.com/Manage/ChangePassword?target=inIframe",
                label: "Change password",
                children: [],
            },
            {
                id: 5,
                path: "https://account.spinise.com/Manage/TokenManagement?target=inIframe",
                label: "Token Management",
                children: [],
            },
        ]
    },
    {
        id: 108,
        label: 'System Management',
        path: '/pvh_admin',
        icon: 'icon-textile-conditions',
        meta: {},
        children: [
            {
                id: 106,
                label: 'FieldSetting',
                path: "fieldList",
                children: [],
            },
            {
                id: 107,
                label: 'FileSetting',
                path: "fileList",
                children: [],
            },
            {
                id: 108,
                label: 'AddressSetting',
                path: "vendorAddress",
                children: [],
            },
            {
                id: 121,
                label: 'Enumeration Management',
                path: "enumeration",
                children: [],
            },
            {
                id: 119,
                label: 'QR Code Access Record',
                path: "qrCodeRecode",
                children: [],
            },
            {
                id: 120,
                label: 'Subject',
                path: "subjectList",
                children: [],
            },
            {
                id: 122,
                label: 'LogManagement',
                path: "logList",
                children: [],
            },
        ]
    },
    //{
    //    id: 198,
    //    label: 'Admin Header',
    //    path: '/pvh_admin',
    //    icon: 'el-icon-s-opportunity',
    //    meta: {},
    //    children:[]
    //}
]
const second = [
    {
        id: 15,
        label: "高级路由",
        path: '/advanced-router',
        icon: 'icon-iconset0265',
        meta: {},
        children: [
            {
                id: 16,
                label: "动态路由",
                path: 'mutative-router',
                component: 'views/advanced-router/mutative-router',
                //配置某些路由激活菜单高亮
                group: [
                    '/advanced-router/mutative-detail'
                ],
                children: []
            },
            {
                id: 17,
                label: "参数路由",
                path: 'argument-page',
                component: 'views/advanced-router/argument-page',
                group: ['/advanced-router/argument-detail'],
                children: []
            },
        ]
    },
    {
        id: 8,
        label: "综合错误",
        path: '/logs',
        icon: 'icon-cuowu',
        meta: {},
        children: [
            {
                id: 15,
                label: "错误页面",
                path: 'page',
                component: 'page/logs/page',
                children: []
            },
            {
                id: 15,
                label: "错误日志",
                path: 'error',
                component: 'page/logs/error',
                children: []
            }
        ]
    },
    {
        id: 23,
        label: "环境变量",
        icon: 'icon-dongtai',
        path: '/dev',
        component: 'views/dev/index',
        meta: {

        },
        children: []
    },
    {
        id: 25,
        label: "json树形",
        icon: 'icon-dongtai',
        path: '/json-tree',
        component: 'views/json-tree/index',
        meta: {},
        children: []
    },
    {
        id: 24,
        label: "数据持久化",
        path: `/store`,
        component: 'views/store/index',
        icon: 'icon-huanyingye',
        meta: {},
        children: []
    },
    {
        id: 25,
        label: "剪切板",
        path: `/clipboard`,
        component: 'views/clipboard/index',
        icon: 'icon-canshu',
        meta: {},
        children: []
    },
    {
        id: 25,
        label: "标签页操作",
        path: `/tags`,
        component: 'views/tags/index',
        icon: 'icon-canshu',
        meta: {},
        children: []
    },
    {
        id: 26,
        label: "灰度模式",
        path: `/gray`,
        component: 'views/gray/index',
        icon: 'icon-shujuzhanshi2',
        meta: {},
        children: []
    },
    {
        id: 9,
        label: "系统管理",
        path: '/admin',
        icon: 'icon-liuliangyunpingtaitubiao08',
        children: [
            {
                id: 20,
                label: "用户管理",
                path: 'user',
                component: 'views/admin/user/index',
                children: []
            },
            {
                id: 21,
                label: "角色管理",
                path: 'role',
                component: 'views/admin/role/index',
                children: []
            }
        ]
    }
]
export default ({ mock }) => {
    if (!mock) return;
    var menu = [];
    if (CurrpReleaseSystem == "PVH") {
        menu = [PVHfirst, second];
    } else {
        menu = [first, second];
    }

    Mock.mock('/user/getMenu', 'get', (res) => {
        let body = JSON.parse(res.body);
        return {
            data: menu[body.type] || []
        }
    })
    Mock.mock('/user/getTopMenu', 'get', () => {
        return {
            data: top
        }
    })
}

