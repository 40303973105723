var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "span",
    [
      _c("i", {
        staticClass: "iconfont icon-password",
        on: { click: _vm.handleLock }
      }),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "设置锁屏密码",
            visible: _vm.box,
            width: "30%",
            "append-to-body": ""
          },
          on: {
            "update:visible": function($event) {
              _vm.box = $event
            }
          }
        },
        [
          _c(
            "el-form",
            { ref: "form", attrs: { model: _vm.form, "label-width": "80px" } },
            [
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "锁屏密码",
                    prop: "passwd",
                    rules: [{ required: true, message: "锁屏密码不能为空" }]
                  }
                },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入锁屏密码" },
                    model: {
                      value: _vm.form.passwd,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "passwd", $$v)
                      },
                      expression: "form.passwd"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.handleSetLock }
                },
                [_vm._v("确 定")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }