var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.showTag
    ? _c("div", { staticClass: "avue-tags" }, [
        _c(
          "div",
          {
            staticClass: "avue-tags__box",
            class: { "avue-tags__box--close": !_vm.website.isFirstPage }
          },
          [
            _c(
              "el-tabs",
              {
                attrs: { type: "card", closable: _vm.tagLen !== 1 },
                on: { "tab-click": _vm.openTag, edit: _vm.menuTag },
                model: {
                  value: _vm.active,
                  callback: function($$v) {
                    _vm.active = $$v
                  },
                  expression: "active"
                }
              },
              _vm._l(_vm.tagList, function(item) {
                return _c("el-tab-pane", {
                  key: item.value,
                  attrs: { label: _vm.$t(item.label), name: item.value }
                })
              }),
              1
            ),
            _c(
              "el-dropdown",
              { staticClass: "avue-tags__menu" },
              [
                _c("el-button", { attrs: { type: "primary", size: "mini" } }, [
                  _vm._v("\n        " + _vm._s(_vm.$t("More")) + "\n        "),
                  _c("i", { staticClass: "el-icon-arrow-down el-icon--right" })
                ]),
                _c(
                  "el-dropdown-menu",
                  { attrs: { slot: "dropdown" }, slot: "dropdown" },
                  [
                    _c(
                      "el-dropdown-item",
                      {
                        nativeOn: {
                          click: function($event) {
                            return _vm.closeOthersTags.apply(null, arguments)
                          }
                        }
                      },
                      [_vm._v(_vm._s(_vm.$t("Close Other")))]
                    ),
                    _c(
                      "el-dropdown-item",
                      {
                        nativeOn: {
                          click: function($event) {
                            return _vm.closeAllTags.apply(null, arguments)
                          }
                        }
                      },
                      [_vm._v(_vm._s(_vm.$t("Close All")))]
                    )
                  ],
                  1
                )
              ],
              1
            )
          ],
          1
        )
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }