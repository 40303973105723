var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-breadcrumb",
    { staticClass: "avue-breadcrumb-list", attrs: { separator: "/" } },
    [
      _vm._l(_vm.tagCurrent, function(item, index) {
        return [
          _c(
            "el-breadcrumb-item",
            { key: index, attrs: { to: { path: item.value } } },
            [_vm._v(_vm._s(item.label))]
          )
        ]
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }