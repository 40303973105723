<template>
  <div class="avue-top">
    <div class="top-bar__left">
      <div class="avue-breadcrumb"
           v-if="showCollapse">
        <i class="iconfont icon-caidanzuo"
           :class="[{ 'icon-caidanyou': isCollapse }]"
           @click="setCollapse" style="font-size:25px !important;line-height:50px;"></i>
      </div>
    </div>
    <h1 class="top-bar__title">
      <div class="top-bar__item top-bar__item--show"
           v-if="showMenu">
        <!-- <top-menu></top-menu> -->
      </div>
      <span class="top-bar__item"
            v-if="showSearch">
        <top-search></top-search>
      </span>
    </h1>
    <div class="top-bar__right">
        <template>
            <el-select v-model="langVal" @change="getValue" style="width:100px;margin-right:15px;" size="small">
                <el-option v-for="item in language" :key="item.value" :label="item.label" :value="item.value">
                </el-option>
            </el-select>
        </template>
        <el-tooltip v-if="showColor"
                    effect="dark"
                    content="主题色"
                    placement="bottom">
            <div class="top-bar__item">
                <top-color></top-color>
            </div>
        </el-tooltip>
        <el-tooltip v-if="showDebug"
                    effect="dark"
                    :content="logsFlag?'没有错误日志':`${logsLen}条错误日志`"
                    placement="bottom">
            <div class="top-bar__item">
                <!-- <top-logs></top-logs> -->
            </div>
        </el-tooltip>
        <el-tooltip v-if="showLock"
                    effect="dark"
                    content="锁屏"
                    placement="bottom">
            <div class="top-bar__item">
                <top-lock></top-lock>
            </div>
        </el-tooltip>
        <el-tooltip v-if="showTheme"
                    effect="dark"
                    content="特色主题"
                    placement="bottom">
            <div class="top-bar__item top-bar__item--show">
            </div>
        </el-tooltip>
        <!-- <div class="top-bar__item">
      <top-msg></top-msg>
    </div> -->

        <el-tooltip v-if="showFullScren"
                    effect="dark"
                    :content="isFullScren?'退出全屏':'全屏'"
                    placement="bottom">
            <div class="top-bar__item" v-show="this.$route.name =='首页'|| this.$route.name =='Real time production Kanban' || this.$route.name =='Typesetting Management'||this.$route.name =='Personnel ReplacementS'||this.$route.name =='Equipment Details'||this.$route.name =='Process Modification'||this.$route.name =='Operational Manage'||this.$route.name =='Home Page'||this.$route.name =='PVH Home Page'||this.$route.name =='TracingReport'">
                <i :class="isFullScren?'iconfont icon-tuichuquanping':'iconfont icon-caozuo_quanpingfangda'"
                   @click="handleScreen"></i>
            </div>
        </el-tooltip>
        <el-tooltip effect="dark"
                    content="用户头像"
                    placement="bottom">
            <img class="top-bar__img"
                 v-bind:src="'https://gravatar.zeruns.tech/avatar/'+ oidcUser.name + '?s=64&d=identicon'">
        </el-tooltip>
        <el-dropdown>
            <span class="el-dropdown-link">
                {{oidcUser.name}}
                <i class="el-icon-arrow-down el-icon--right"></i>
            </span>
            <el-dropdown-menu slot="dropdown">
                <el-dropdown-item>
                    <a :href="personalURL" target="_blank">{{$t('personal Information')}}</a>
                </el-dropdown-item>
                <el-dropdown-item>
                    <router-link to="/company"> {{$t('Change Company')}}</router-link>
                </el-dropdown-item>
                <el-dropdown-item>
                    <router-link to="/logout"> {{$t('Exit System')}}</router-link>
                </el-dropdown-item>
            </el-dropdown-menu>
        </el-dropdown>
        <top-setting></top-setting>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapActions, mapState } from "vuex";
import { listenfullscreen } from "@/util/util";
import topLock from "./top-lock";
import topMenu from "./top-menu";
import topSearch from './top-search';
import topBreadcrumb from "./top-breadcrumb";
import topColor from "./top-color";
import topTheme from "./top-theme";
import topLogs from "./top-logs";
import topSetting from "./top-setting";
import topMsg from "./top-msg";
import { oidcSettings } from "@/config/env";

    export default {
        components: { topLock, topMenu, topSearch, topBreadcrumb, topColor, topTheme, topLogs, topSetting, topMsg },
        name: "top",
        data() {
            
            let defVlue = "";
            if (this.$i18n.locale == "ch") {
                defVlue = "Chinese";
            } else {
                defVlue = "English";
            }
            return {
                personalURL: "",
                fullscreen: false,
                language: [
                    {
                        value: 'English',
                        label: 'English'
                    },
                    {
                        value: 'Chinese',
                        label: '中文'
                    }
                ],
                langVal: defVlue,
                confirmLogout: false
            };
        },
        filters: {},
        watch: {
            $route(to, from) {
                console.log(this.$route.name)
            }
        },
        created() {
        },
        mounted() {
            this.personalURL = oidcSettings.authority +"/Manage";
            listenfullscreen(this.setScreen);
        },
        computed: {
            ...mapState({
                showDebug: state => state.common.showDebug,
                showColor: state => state.common.showColor,
                showTheme: state => state.common.showTheme,
                showLock: state => state.common.showLock,
                showFullScren: state => state.common.showFullScren,
                showCollapse: state => state.common.showCollapse,
                showSearch: state => state.common.showSearch,
                showMenu: state => state.common.showMenu
            }),
            ...mapGetters([
                "userInfo",
                "isFullScren",
                "tagWel",
                "tagList",
                "isCollapse",
                "tag",
                "logsLen",
                "logsFlag"
            ]),
            ...mapGetters('oidcStore', [
                "oidcUser"
            ])
        },

        methods: {
            getValue(value) {
                if (value == 'Chinese') {
                    localStorage.setItem('locale', 'ch')
                    this.$i18n.locale = localStorage.getItem('locale');
                } else if (value == 'English') {
                    localStorage.setItem('locale', 'en')
                    this.$i18n.locale = localStorage.getItem('locale')
                }
            },
            handleScreen() {
                // fullscreenToggel();
                this.handleFullScreen()
            },
            // 全屏事件
            handleFullScreen() {
                // let element = document.documentElement;//设置后就是我们平时的整个页面全屏效果
                let element = document.getElementById('home_container');//设置后就是   id==con_lf_top_div 的容器全屏
                if (this.fullscreen) {
                    console.log(1)
                    if (document.exitFullscreen) {
                        document.exitFullscreen();
                    } else if (document.webkitCancelFullScreen) {
                        document.webkitCancelFullScreen();
                    } else if (document.mozCancelFullScreen) {
                        document.mozCancelFullScreen();
                    } else if (document.msExitFullscreen) {
                        document.msExitFullscreen();
                    }
                } else {
                    if (element.requestFullscreen) {
                        element.requestFullscreen();
                    } else if (element.webkitRequestFullScreen) {
                        element.webkitRequestFullScreen();
                    } else if (element.mozRequestFullScreen) {
                        element.mozRequestFullScreen();
                    } else if (element.msRequestFullscreen) {
                        // IE11
                        element.msRequestFullscreen();
                    }
                }
                this.fullscreen = false;
            },
            setCollapse() {
                this.$store.commit("SET_COLLAPSE");
            },
            setScreen() {
                let element = document.getElementById('home_container');
                if (!this.isFullScren) {
                    if (this.$route.path == "/device/devFile" || this.$route.path == "/listArray/pepoleChange" || this.$route.path == "/wkln/wklnChange" || this.$route.path == "/productionManagement/additionToMachine") {
                        element.style.background = "#F2F2F2";
                        document.querySelector(".outFormDiv").style.top = "0";
                        document.querySelector(".outFormDiv").style.width = "100%";
                    }
                    if (this.$route.path == "/visualData/index") {
                        element.style.cssText = "background:url('/img/daBG.png') center 100% no-repeat;";
                        document.getElementById("topTit").style.display = "block";
                        document.getElementById("topSel").style.display = "none";
                        var pub2 = document.querySelectorAll(".public");
                        var cha2 = document.querySelectorAll(".charts");
                        var bdDiv2 = document.querySelectorAll(".bdDiv");
                        for (var i = 0; i < pub2.length; i++) {
                            pub2[i].style.cssText = "background:transparent !important;padding-left:0;border:0;";
                            pub2[i].children[0].style.cssText = "display:none;";
                            pub2[i].children[1].style.cssText = "color:#fff;font-weight:500;width:100%;text-align:center;";
                        }
                        for (var j = 0; j < cha2.length; j++) {
                            cha2[j].style.cssText = "background:transparent !important;";
                        }
                        for (var k = 0; k < bdDiv2.length; k++) {
                            bdDiv2[k].style.cssText = "background:transparent !important;border:1px solid #00bdf2;border-radius:0;color:#fff;";
                            var cs2 = bdDiv2[k].className;
                            cs2 = cs2.concat(" afDiv");
                            bdDiv2[k].className = cs2;
                        }
                    }
                    if (this.$route.path == "/tracingSearch/pvhIndex") {
                        element.style.background = "#F2F2F2";
                        document.querySelector("#pvhinfo_container").style.cssText = "padding:1.25rem;";
                        document.querySelector(".chartsMapDiv").style.height = "35.75rem";
                        for (var i = 0; i < document.querySelectorAll(".chartsDiv").length; i++) {
                            document.querySelectorAll(".chartsDiv")[i].style.height = "19rem";
                        }
                        document.querySelector("#mapBig").style.display = "none";
                    }
                } else {
                    if (this.$route.path == "/device/devFile" || this.$route.path == "/listArray/pepoleChange" || this.$route.path == "/wkln/wklnChange" || this.$route.path == "/productionManagement/additionToMachine") {
                        element.style.background = "transparent";
                        document.querySelector(".outFormDiv").style.top = "100px";
                        document.querySelector(".outFormDiv").style.width = "calc(100% - 13.75rem)";
                    }

                    if (this.$route.path == "/visualData/index") {
                        element.style.cssText = "background:transparent";
                        document.getElementById("topTit").style.display = "none";
                        document.getElementById("topSel").style.display = "block";
                        var pub3 = document.querySelectorAll(".public");
                        var cha3 = document.querySelectorAll(".charts");
                        var bdDiv3 = document.querySelectorAll(".bdDiv");
                        for (var i = 0; i < pub3.length; i++) {
                            pub3[i].style.cssText = "background:#fff !important;padding-left:0.8125rem;border-bottom:1px solid #ebebe;";
                            pub3[i].children[0].style.cssText = "display:block;";
                            pub3[i].children[1].style.cssText = "color:#000;font-weight:600;width:auto;text-align:left;";
                        }
                        for (var i = 0; i < cha3.length; i++) {
                            cha3[i].style.cssText = "background:#fff !important;";
                        }
                        for (var i = 0; i < bdDiv3.length; i++) {
                            bdDiv3[i].style.cssText = "background:#fff !important;border:0;border-radius:0.625rem;color:#000;";
                            var cs3 = bdDiv3[i].className;
                            cs3 = cs3.replace(" afDiv", "");
                            bdDiv3[i].className = cs3;
                        }
                        //document.querySelectorAll(".public").style.cssText = "background:#fff !important;"
                        //document.querySelectorAll(".charts").style.cssText = "background:#fff !important;"
                    }
                    if (this.$route.path == "/tracingSearch/pvhIndex") {
                        element.style.background = "transparent";
                        document.querySelector("#pvhinfo_container").style.cssText = "padding:0 1.25rem 1.25rem;";
                        document.querySelector(".chartsMapDiv").style.height = "28.75rem";
                        for (var i = 0; i < document.querySelectorAll(".chartsDiv").length; i++) {
                            document.querySelectorAll(".chartsDiv")[i].style.height = "15rem";
                        }
                        document.querySelector("#mapBig").style.display = "inline-block";
                    }
                }
                this.$store.commit("SET_FULLSCREN");
            },
            logout() {
               this.confirmLogout = true;
               console.log("logout");
            },
            closeModel() {
                this.confirmLogout = false;
            } 
        }
    };
</script>

<style lang="scss" scoped>
  .top-bar__right {
    margin-top: -30px;
  }
</style>

