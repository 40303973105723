import request from '@/router/axios';
import { pvhApiBase } from '@/config/env';

export const getUserInfo = () => request({
  url: '/user/getUserInfo',
  method: 'get'
});

export const getTopMenu = () => request({
  url: '/user/getTopMenu',
  method: 'get'
});

export const getMenu = (type = 0) => request({
  url: pvhApiBase + "Cashier/GetMenus",
  method: 'get',
  // data: {
  //   type
  // }
});

export const getMenuAll = () => request({
  url: pvhApiBase + "Cashier/GetMenus",
  method: 'get',
  // data: {
  //   type: 0
  // }
});

export const getTableData = (page) => request({
  url: '/user/getTable',
  method: 'get',
  data: {
    page
  }
});

export const logout = () => request({
  url: '/user/logout',
  method: 'get'
})