var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("el-autocomplete", {
    staticClass: "top-search",
    attrs: {
      "popper-class": "my-autocomplete",
      "fetch-suggestions": _vm.querySearch,
      placeholder: _vm.$t("Please enter the search content")
    },
    on: { select: _vm.handleSelect },
    scopedSlots: _vm._u([
      {
        key: "default",
        fn: function(ref) {
          var item = ref.item
          return [
            _c("i", { class: [item[_vm.iconKey], "icon"] }),
            _c("div", { staticClass: "name" }, [
              _vm._v(_vm._s(item[_vm.labelKey]))
            ]),
            _c("p", { staticClass: "addr" }, [
              _vm._v(_vm._s(item[_vm.pathKey]))
            ])
          ]
        }
      }
    ]),
    model: {
      value: _vm.value,
      callback: function($$v) {
        _vm.value = $$v
      },
      expression: "value"
    }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }