import Cookies from 'js-cookie'
import store from '@/store'
import {} from 'vuex-oidc'

export function getToken() {
    return store.state.oidcStore.access_token;
}

export function removeGrop() {
    return Cookies.remove("gropId")
}

export function getGrop() {
    return Cookies.get("gropId")
}