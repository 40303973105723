/**
 * 全站http配置
 *
 * header参数说明
 * serialize是否开启form表单提交
 * isToken是否需要token
 */
import axios from 'axios'
import store from '@/store/';
import router from '@/router/router'
import { serialize } from '@/util/util'
import { getToken, getGrop } from '@/util/auth'
import { Message } from 'element-ui'
import website from '@/const/website';
import NProgress from 'nprogress' // progress bar
import 'nprogress/nprogress.css' // progress bar style
axios.defaults.timeout = 10000;
//返回其他状态吗
axios.defaults.validateStatus = function(status) {
    return status >= 200 && status <= 500; // 默认的
};
//跨域请求，允许保存cookie
axios.defaults.withCredentials = true;
// NProgress Configuration
NProgress.configure({
    showSpinner: false
});
//HTTPrequest拦截
axios.interceptors.request.use(config => {

    NProgress.start() // start progress bar
    const isToken = (config.data || {}).isToken === false;
    console.log(isToken);
    if (getToken() && !isToken) {
        config.headers['Authorization'] = 'Bearer ' + getToken() // 让每个请求携带token--['Authorization']为自定义key 请根据实际情况自行修改
        config.headers['corpId'] = getGrop();
    }
    //headers中配置serialize为true开启序列化
    if (config.methods === 'post' && config.headers.serialize) {
        config.data = serialize(config.data);
        delete config.data.serialize;
    }

    return config
}, error => {
    return Promise.reject(error)
});
//HTTPresponse拦截
axios.interceptors.response.use(res => {
    NProgress.done();
    const status = Number(res.status) || 200;
    console.log(status);
    const statusWhiteList = website.statusWhiteList || [];
    const message = res.data.message || '未知错误';
    //如果请求为200则放过，否者默认统一处理,或者在website中配置statusWhiteList白名单自行处理
    if (status !== 200 && !statusWhiteList.includes(status)) {
        Message({
            message: message,
            type: 'error'
        })
        return Promise.reject(new Error(message))
    }
    //如果是401则跳转到登录页面
    if (status === 401) store.dispatch('FedLogOut').then(() => router.push({ path: '/login' }));
    Number(res.status) == "302" ? store.dispatch('FedLogOut').then(() => router.push({ path: '/login' })):"";
    if (res.data.code != "0000"&&res.data.msg) Message({type:"error",message:res.data.msg});
   
    // 如果是白名单类型放入catch自行处理
    if (status !== 200) return Promise.reject(res);
    return res;
}, error => {
    console.log(error);
    NProgress.done();
    if (error == "Error: Request failed with status code 888") store.dispatch('FedLogOut').then(() => router.push({ path: '/login' }));
    return Promise.reject(new Error(error));
})
console.log(axios)
export default axios;