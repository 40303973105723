<template>
    <div>

    </div>
</template>
<script>
    import { mapActions } from 'vuex'
    console.log(58)
    export default {
        name: 'OidcCallback',
        methods: {
            ...mapActions('oidcStore', [
                'oidcSignInCallback'
            ])
        },
        watch: {
            $route: function () {
                console.log('asd')
            }
        },
        mounted() {
            console.log(2)
        },
        created() {
            console.log(1)
            this.oidcSignInCallback()
                .then((redirectPath) => {
                    console.log(redirectPath)
                    this.$router.push(redirectPath)
                })
                .catch((err) => {
                    console.log(err)
                    this.$router.push('/signin-oidc-error') // Handle errors any way you want
                })
        }
    }
</script>
