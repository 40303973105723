var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("el-color-picker", {
    staticClass: "theme-picker",
    attrs: { size: "small", "popper-class": "theme-picker-dropdown" },
    model: {
      value: _vm.themeVal,
      callback: function($$v) {
        _vm.themeVal = $$v
      },
      expression: "themeVal"
    }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }